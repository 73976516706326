import logo from './logo.svg';
import './App.css';
import Hero from './Components/hero';
import Footer from './Components/footer';
import LearnMore from './Components/learn_more';

function App() {
  return (
    <div className="App">
     <Hero />
     <LearnMore />
     <Footer />
    </div>
  );
}

export default App;
